<template>
  <div class="bg-primary-5 border-r-10 p-4">
    <h1 class="text-primary-0 fs-4 mb-0 fw-bold mb-3">Last transactions</h1>

    <DataTable
      :value="financialInfo.lastPays.data"
      responsiveLayout="scroll"
      :loading="financialInfo.lastPays.isLoading"
      class="bg-white border-r-10 overflow-hidden"
    >
      <template #empty> No last transactions found. </template>
      <template #loading>
        Loading last transactions data. Please wait.
      </template>
      <column></column>
      <column
        v-for="(header, index) in financialInfo.lastPays.header"
        :key="index"
        :field="header.field"
        :header="header.name"
        style="min-width: 14rem"
      >
        <template #body="{ data }">
          <p v-if="header.field !== 'status' && header.field !== 'date'">
            <span v-if="header.field === 'amount'">$</span>
            {{ data[header.field] }}
          </p>
          <div
            class="
              bg-white
              py-1
              px-3
              bl-shadow
              d-flex
              gap-2
              rounded-pill
              w-content
              align-items-center
            "
            v-if="header.field === 'status' && data[header.field]"
          >
            <div
              class="status bg-feedback-success"
              :class="validateStatus(data[header.field])"
            ></div>

            <span class="text-neutro-1">
              {{ data[header.field] }}
            </span>
          </div>
          <p v-if="header.field === 'date' && data[header.field]">
            {{ moment(new Date(data[header.field])).format("MM/DD/YYYY") }}
          </p>
        </template>
      </column>
    </DataTable>
  </div>
</template>

<script>
import moment from "moment";
import useFinancial from "@/modules/admin/composables/Analitycs/Financial/useFinancial";
export default {
  setup() {
    const { financialInfo } = useFinancial();

    const validateStatus = (textStatus) => {
      const text = textStatus.toLowerCase().trim().replaceAll(" ", "-");
      if (text === "confirmed") return "bg-feedback-success";
      else if (text === "waiting-payment") return "bg-feedback-warning";
      else if (text === "payment-expired") return "bg-feedback-error";
      else return "bg-feedback-warning";
    };

    return {
      financialInfo,
      validateStatus,
      moment,
    };
  },
};
</script>