<template>
  <div class="bg-primary-5 border-r-10 p-4 mt-4">
    <div class="d-flex align-items-center justify-content-between">
      <h1 class="fs-3 text-primary-0 fw-bold mb-0">Earning</h1>
      <select-button class="select-buttons-custom w-100" v-model="currentChart" :options="selectOptions" />
    </div>
    <hr class="line-blue" />
    <div class="d-flex justify-content-between mb-4">
      <div>
        <h1 class="fs-3 text-primary-0 fw-bold mb-0">
          $ {{ currentChartDataExport.total[currentChart].total }}
        </h1>
        <p class="text-neutro-1 fs-6">Earnings overtime</p>
      </div>
      <div>
        <h1 class="fs-3 text-primary-0 fw-bold mb-0">
          {{ currentChartDataExport.total[currentChart].order }}
        </h1>
        <p class="text-neutro-1 fs-6">Total orders</p>
      </div>
    </div>
    <Chart type="bar" :data="currentChartDataExport[currentChart] " :options="chartOptions"
      :plugins="[ChartJsPluginDataLabels, bgColor]" />
  </div>
</template>

<script>
import useFinancial from "@/modules/admin/composables/Analitycs/Financial/useFinancial";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
import bgColor from "@/shared/utils/charts/bgColor";

export default {
  setup() {
    const {
      selectOptions,
      currentChart,
      currentChartData,
      chartOptions,
      total,
      currentChartDataExport
    } = useFinancial();
    return {
      selectOptions,
      currentChart,
      currentChartData,
      chartOptions,
      total,
      currentChartDataExport,
      bgColor,
      ChartJsPluginDataLabels,
    };
  },
};
</script>
