import { computed, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import { jsPDF } from "jspdf";
import moment from "moment";
import html2canvas from "html2canvas";
const useFinancial = () => {
  const store = useStore();
  const selectOptions = ref(["Weekly", "Monthly"]);
  const optionsFilterReport = ref("Weekly Financial Earning");
  const isLoadingFilterReport = ref(false);
  const isExportingChart = ref(false);
  const currentChart = ref("Weekly");
  const total = ref({
    Weekly: {
      order: "0.00",
      total: "0.00",
    },
    Monthly: {
      order: "0.00",
      total: "0.00",
    },
  });

  const filterReport = ref({
    date_start: "",
    date_end: "",
  });
  const financialInfo = computed(() => store.getters["admins/getFinancial"]);
  const getLastTransaction = () => store.dispatch("admins/financialStart");

  const exportChartModal = computed(
    () => store.getters["admins/analyticsFinancialModal"].exportCharts
  );
  const optionsCharts = ref([
    {
      label: "Weekly Financial Earnings",
    },
    {
      label: "Monthly Financial Earnings",
    },
    {
      label: "Weekly and Monthly Financial Earnings",
    },
    {
      label: "10 Lastest Transactions",
    },
    {
      label: "Transactions Current Week",
    },
  ]);

  const currentChartData = ref({
    Weekly: {
      labels: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      datasets: [
        {
          type: "bar",
          label: "Weekly",
          data: [0, 0, 0, 0, 0, 0],
          borderRadius: 20,
          backgroundColor: [
            "#74DAD9",
            "#FAB086",
            "#74DAD9",
            "#74DAD9",
            "#74DAD9",
            "#74DAD9",
            "#74DAD9",
          ],
        },
      ],
    },
    Monthly: {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      datasets: [
        {
          type: "bar",
          label: "Monthly",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderRadius: 20,
          backgroundColor: [
            "#74DAD9",
            "#FAB086",
            "#74DAD9",
            "#74DAD9",
            "#74DAD9",
            "#74DAD9",
            "#74DAD9",
          ],
        },
      ],
    },
  });

  const currentChartDataExport = computed(() => {
    return store.getters["admins/exportChartsFinancialData"];
  });

  const setInfoPays = (total_pays_week, total_pays_month) => {
    Object.entries(total_pays_week).forEach(([key, value]) => {
      switch (key) {
        case "Monday":
          currentChartDataExport.value.Weekly.datasets[0].data[0] = value;
          break;
        case "Tuesday":
          currentChartDataExport.value.Weekly.datasets[0].data[1] = value;
          break;
        case "Wednesday":
          currentChartDataExport.value.Weekly.datasets[0].data[2] = value;
          break;
        case "Thursday":
          currentChartDataExport.value.Weekly.datasets[0].data[3] = value;
          break;
        case "Friday":
          currentChartDataExport.value.Weekly.datasets[0].data[4] = value;
          break;
        case "Saturday":
          currentChartDataExport.value.Weekly.datasets[0].data[5] = value;
          break;
        case "total_money":
          currentChartDataExport.value.total.Weekly.total = value;
          break;
        default:
          currentChartDataExport.value.total.Weekly.order = value;
      }
    });
    Object.entries(total_pays_month).forEach(([key, value]) => {
      switch (key) {
        case "January":
          currentChartDataExport.value.Monthly.datasets[0].data[0] = value;
          break;
        case "February":
          currentChartDataExport.value.Monthly.datasets[0].data[1] = value;
          break;
        case "March":
          currentChartDataExport.value.Monthly.datasets[0].data[2] = value;
          break;
        case "April":
          currentChartDataExport.value.Monthly.datasets[0].data[3] = value;
          break;
        case "May":
          currentChartDataExport.value.Monthly.datasets[0].data[4] = value;
          break;
        case "June":
          currentChartDataExport.value.Monthly.datasets[0].data[5] = value;
          break;
        case "July":
          currentChartDataExport.value.Monthly.datasets[0].data[6] = value;
          break;
        case "August":
          currentChartDataExport.value.Monthly.datasets[0].data[7] = value;
          break;
        case "September":
          currentChartDataExport.value.Monthly.datasets[0].data[8] = value;
          break;
        case "October":
          currentChartDataExport.value.Monthly.datasets[0].data[9] = value;
          break;
        case "November":
          currentChartDataExport.value.Monthly.datasets[0].data[10] = value;
          break;
        case "December":
          currentChartDataExport.value.Monthly.datasets[0].data[11] = value;
          break;
        case "total_money":
          currentChartDataExport.value.total.Monthly.total = value;
          break;
        default:
          currentChartDataExport.value.total.Monthly.order = value;
      }
    });
  };

  watchEffect(() => {
    let { totals } = store.getters["admins/getFinancial"];
    let { data } = totals;
    let { total_pays_week, total_pays_month } = data;
    if (total_pays_week !== undefined || total_pays_month !== undefined)
      setInfoPays(total_pays_week, total_pays_month);
  });

  const setLastPays = (last_pays) => {
    store.commit("admins/setFinancialLastPay", { data: last_pays });
  };

  const reportFilterReport = async () => {
    isLoadingFilterReport.value = true;
    const date_start = moment(filterReport.value.date_start).format(
      "YYYY-MM-DD"
    );
    const date_end = moment(filterReport.value.date_end).format("YYYY-MM-DD");
    const { success, json } = await store.dispatch(
      "admins/filterReportFinancial",
      { payload: { date_start, date_end } }
    );
    if (success) {
      setInfoPays(json.total_pays_week, json.total_pays_month);
      setLastPays(json.last_pays);
    }
    isLoadingFilterReport.value = false;
  };

  const chartOptions = ref({
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
      datalabels: {
        color: "white",
        textAlign: "center",
        font: {
          weight: "bold",
          size: 16,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
      y: {
        min: 0,
        max: 20,
        ticks: {
          callback: function (val) {
            return "$" + this.getLabelForValue(val);
          },
          stepSize: 5,
        },
      },
    },
  });

  const amounts = [
    {
      label: "Today’s Money",
      icon: "account_balance_wallet",
      key: "money_today",
    },
    {
      label: "Today’s users",
      icon: "language",
      key: "users_today",
    },
    {
      label: "new clients",
      icon: "account_box",
      key: "new_clients",
    },
    {
      label: "total sales",
      icon: "real_estate_agent",
      key: "total_sales",
    },
  ];

  const openToast = (message, status) => {
    const notification = { isOpen: true };
    notification.message = message;
    notification.status = status;
    store.commit("shared/uiNotificationStatus", notification);
    store.dispatch("shared/resetNotificationStatus");
  };

  const exportChart = () => {
    isExportingChart.value = true;
    const chart = document.querySelector("#financial-view");
    const margin = 10;
    const doc = new jsPDF('p', 'mm', 'a4');
    const scale =
      (doc.internal.pageSize.width - margin * 2) / chart.offsetWidth;
    doc.html(chart, {
      x: margin,
      y: margin,

      background: "#fff",
      html2canvas: { scale },
      callback: function (doc) {
        isExportingChart.value = false;
        var pageCount = doc.internal.getNumberOfPages();
        if(pageCount > 2){
          while(pageCount > 2){
            doc.deletePage(pageCount);
            pageCount--;
          }
          openToast(
            "The charts selected have been exported successfully.",
            "success"
          );
          doc.save("financial.pdf");
        }
       
      },
    });
  };

  const toggleModalReport = () => {
    const { isOpen } = store.getters["admins/exportChartsFinancial"];
    store.commit("admins/exportChartsFinancial", { isOpen: !isOpen });
  };

  return {
    toggleModalReport,
    currentChartDataExport,
    financialInfo,
    optionsCharts,
    exportChartModal,
    isExportingChart,
    selectOptions,
    currentChart,
    reportFilterReport,
    isLoadingFilterReport,
    currentChartData,
    chartOptions,
    amounts,
    getLastTransaction,
    total,
    exportChart,
    optionsFilterReport,
    filterReport,
  };
};

export default useFinancial;
