<template>
  <div id="financial-view">
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h1 class="text-primary-1 fs-1 fw-bold">Financial</h1>
      <div class="
          d-flex
          align-items-center
          gap-3
        ">
        <div class="field-calendar d-flex gap-2 align-items-center">
          <p>Start:</p>
          <Calendar id="icon" show-icon="true" v-model="filterReport.date_start" class="w-auto calendar-small"
            name="date_start" dateFormat="M dd" />
        </div>
        <div class="field-calendar d-flex gap-2 align-items-center">
          <p>Finish:</p>
          <Calendar id="icon" show-icon="true" v-model="filterReport.date_end" class="w-auto calendar-small"
            name="date_end" dateFormat="M dd" />
        </div>
        <button style="" type="button" class="
           btn text-secondary-1 d-flex gap-2 align-items-center border-secondary-1 w-auto bg-hover-light-secondary-1 p-2 px-4
          " @click="reportFilterReport" :disabled="isLoadingFilterReport">
          <i v-if="!isLoadingFilterReport" class="pi pi-filter"></i>
          <i v-else class="pi pi-spin pi-spinner"></i>

          Filter
        </button>
      </div>
      <button @click="exportChart" :disabled="isExportingChart" class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          py-2
          px-4
          d-flex
          align-items-center
          gap-2
        ">
        <i v-if="!isExportingChart" class="pi pi-download"></i>
        <i v-else class="pi pi-spin pi-spinner"></i>
        Export charts
      </button>
    </div>
    <charts-component />
    <amounts-component />
    <last-transactions-component />
  </div>
</template>

<script>
import ChartsComponent from "@/modules/admin/components/Analytics/Financial/ChartsComponent";
import AmountsComponent from "@/modules/admin/components/Analytics/Financial/AmountsComponent";
import LastTransactionsComponent from "@/modules/admin/components/Analytics/Financial/LastTransactionsComponent";

import useFinancial from "@/modules/admin/composables/Analitycs/Financial/useFinancial";
import { onBeforeMount } from "vue";

export default {
  components: {
    ChartsComponent,
    AmountsComponent,
    LastTransactionsComponent,
  },
  setup() {
    const { getLastTransaction, exportChart, toggleModalReport, filterReport, isLoadingFilterReport, isExportingChart, reportFilterReport } = useFinancial();
    onBeforeMount(() => {
      getLastTransaction();
    });
    return {
      exportChart,
      reportFilterReport,
      isExportingChart,
      isLoadingFilterReport,
      filterReport,
      toggleModalReport
    }
  },
};
</script>