<template>
  <div class="amounts mt-4 mb-4" v-if="!financialInfo.totals.isLoading">
    <div
      v-for="(amount, index) in amounts"
      :key="index"
      class="
        bg-white
        shadow-md
        border-r-10
        p-3
        amount
        d-flex
        align-items-center
        justify-content-between
      "
    >
      <div>
        <p class="text-primary-1 text-uppercase fs-6">
          {{ amount.label }}
        </p>
        <div class="d-flex align-items-end gap-2 fw-bold">
          <span class="fs-4 lh-sm">
            {{ financialInfo.totals.data[amount.key].value }}
          </span>
          <span
            :class="[
              validatePercentage(
                financialInfo.totals.data[amount.key].percentage
              ),
            ]"
          >
            {{
              valuePercentage(financialInfo.totals.data[amount.key].percentage)
            }}
          </span>
        </div>
      </div>
      <div class="bg-accent-1 border-r-10 p-2 d-flex align-items-center">
        <span class="text-white material-icons fs-3">
          {{ amount.icon }}
        </span>
      </div>
    </div>
  </div>
  <div class="amounts mt-4 mb-4" v-if="financialInfo.totals.isLoading">
    <skeleton
      v-for="s in [1, 2, 3, 4]"
      :key="s"
      height="6rem"
      borderRadius="10px"
      class="skeleton-dark"
    />
  </div>
</template>

<script>
import useFinancial from "@/modules/admin/composables/Analitycs/Financial/useFinancial";
export default {
  setup() {
    const { amounts, financialInfo } = useFinancial();
    const validatePercentage = (percentage = "0") => {
      const percentage_ = percentage ?? "0" + "";
      if (percentage_.includes("-") || percentage_.startsWith("0"))
        return "text-feedback-error";
      else return "text-feedback-success";
    };

    const valuePercentage = (val) => {
      const value = Math.sign(val);
      if (value === -1) {
        return `- ${Math.abs(val)}%`;
      } else if (value === 0) {
        return `- 0%`;
      } else {
        return `+ ${Math.abs(val)}%`;
      }
    };
    return {
      financialInfo,
      amounts,
      validatePercentage,
      valuePercentage,
    };
  },
};
</script>

<style lang="scss" scoped>
.amounts {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}
.amount {
  display: grid;
  grid-template-columns: 1fr 4rem;
}
</style>